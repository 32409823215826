import Envs from './envs';

export const EPLBKS_TOC_HOST = Envs.getEnvValue('EPLBKS_TOC_HOST');
export const EPLBKS_TOATOC_HOST = Envs.getEnvValue('EPLBKS_TOATOC_HOST');
export const EPLBKS_TOC_APP_ID = Envs.getEnvValue('EPLBKS_TOC_APP_ID');
export const EPLBKS_TOC_PREFIX = Envs.getEnvValue('EPLBKS_TOC_PREFIX') || '';
export const EPLBKS_TOATOC_PREFIX = Envs.getEnvValue('EPLBKS_TOATOC_PREFIX') || '';

export const SHHL_TOC_HOST = Envs.getEnvValue('SHHL_TOC_HOST');
export const SHHL_TOATOC_HOST = Envs.getEnvValue('SHHL_TOATOC_HOST');
export const SHHL_TOA_HOST = Envs.getEnvValue('SHHL_TOA_HOST');
export const SHHL_TOC_APP_ID = Envs.getEnvValue('SHHL_TOC_APP_ID');
export const SHHL_TOC_PREFIX = Envs.getEnvValue('SHHL_TOC_PREFIX') || '';
export const SHHL_TOATOC_PREFIX = Envs.getEnvValue('SHHL_TOATOC_PREFIX') || '';
export const SHHL_TOA_PREFIX = Envs.getEnvValue('SHHL_TOA_PREFIX') || '';


export const BJL_TOC_HOST = Envs.getEnvValue('BJL_TOC_HOST');
export const BJL_TOATOC_HOST = Envs.getEnvValue('BJL_TOATOC_HOST');
export const BJL_TOA_HOST = Envs.getEnvValue('BJL_TOA_HOST');
export const BJL_TOC_APP_ID = Envs.getEnvValue('BJL_TOC_APP_ID');
export const BJL_TOC_PREFIX = Envs.getEnvValue('BJL_TOC_PREFIX') || '';
export const BJL_TOATOC_PREFIX = Envs.getEnvValue('BJL_TOATOC_PREFIX') || '';
export const BJL_TOA_PREFIX = Envs.getEnvValue('BJL_TOA_PREFIX') || '';

export const MERCER_TOATOC_HOST = Envs.getEnvValue('MERCER_TOATOC_HOST');
export const MERCER_TOATOC_APP_ID = Envs.getEnvValue('MERCER_TOATOC_APP_ID');
export const MERCER_TOATOC_PREFIX = Envs.getEnvValue('MERCER_TOATOC_PREFIX') || '';

// export const MEJ_TOATOC_HOST = Envs.getEnvValue('MEJ_TOATOC_HOST');
// export const MEJ_TOATOC_APP_ID = Envs.getEnvValue('MEJ_TOATOC_APP_ID');
export const MEJ_TOATOC_PREFIX = Envs.getEnvValue('MEJ_TOATOC_PREFIX') || '';

export const EKANGJI_TOATOC_HOST = Envs.getEnvValue('EKANGJI_TOATOC_HOST');
export const EKANGJI_TOATOC_APP_ID = Envs.getEnvValue('EKANGJI_TOATOC_APP_ID');
export const EKANGJI_TOATOC_PREFIX = Envs.getEnvValue('EKANGJI_TOATOC_PREFIX') || '';


export const JIANGTAI_TOATOC_HOST = Envs.getEnvValue('JIANGTAI_TOATOC_HOST');
export const JIANGTAI_TOATOC_APP_ID = Envs.getEnvValue('JIANGTAI_TOATOC_APP_ID');
export const JIANGTAI_TOATOC_PREFIX = Envs.getEnvValue('JIANGTAI_TOATOC_PREFIX') || '';
export const ZHGJ_TO_GZH_PREFIX = Envs.getEnvValue('ZHGJ_TO_GZH_PREFIX') || '';

/**
 * 嘉实配置
 */
export const HARVESTINS_TO_GZH_PREFIX = Envs.getEnvValue('HARVESTINS_TO_GZH_PREFIX') || '';
let HARVESTINS_TO_GZH_APP_ID = Envs.getEnvValue('HARVESTINS_TO_GZH_APP_ID');
let HARVESTINS_TO_GZH_HOST = Envs.getEnvValue('HARVESTINS_TO_GZH_HOST');
//打的生产包,适配预生产
if (Envs.getEnvValue('ENV_NAME') === 'PROD') {
    //适配预生产
    if (new RegExp(`^${Envs.getEnvValue('HARVESTINS_TO_GZH_HOST_FOR_PREPROD')}$`).test(window.location.hostname)) {
        HARVESTINS_TO_GZH_HOST = Envs.getEnvValue('HARVESTINS_TO_GZH_HOST_FOR_PREPROD');
        HARVESTINS_TO_GZH_APP_ID = Envs.getEnvValue('HARVESTINS_TO_GZH_APP_ID_FOR_PREPROD');
    }
}
export { HARVESTINS_TO_GZH_HOST, HARVESTINS_TO_GZH_APP_ID }

let ZHGJ_TO_GZH_HOST = Envs.getEnvValue('ZHGJ_TO_GZH_HOST');
//打的生产包,适配预生产
if (Envs.getEnvValue('ENV_NAME') === 'PROD') {
	//适配预生产
	if (new RegExp(`^${Envs.getEnvValue('ZHGJ_TO_GZH_HOST_FOR_PREPROD')}$`).test(window.location.hostname)) {
		ZHGJ_TO_GZH_HOST = Envs.getEnvValue('ZHGJ_TO_GZH_HOST_FOR_PREPROD');
	}
}
export { ZHGJ_TO_GZH_HOST }

let MEJ_TOATOC_HOST = Envs.getEnvValue('MEJ_TOATOC_HOST');
//美世绑定第二个域名
let MEJ_TOATOC_HOST2 = Envs.getEnvValue('MEJ_TOATOC_HOST2') || '';
let MEJ_TOATOC_APP_ID = Envs.getEnvValue('MEJ_TOATOC_APP_ID');
//打的生产包,适配预生产
if (Envs.getEnvValue('ENV_NAME') === 'PROD') {
	//远见适配预生产
	if (new RegExp(`^${Envs.getEnvValue('MEJ_TOATOC_HOST_FOR_PREPROD')}$`).test(window.location.hostname)) {
		MEJ_TOATOC_HOST = Envs.getEnvValue('MEJ_TOATOC_HOST_FOR_PREPROD');
		MEJ_TOATOC_APP_ID = Envs.getEnvValue('MEJ_TOATOC_APP_ID_FOR_PREPROD');
	}
}
export { MEJ_TOATOC_HOST, MEJ_TOATOC_APP_ID }



export enum HostBy {
    /** 已经部署之外的所有 */
    MYSELF = 'myself',
    /** 永达理 */
    YDL = 'ydl',
    /** 上海海力 */
    SHHL = 'shhl',
    /** 保精灵 */
    BJL = 'bjl',
    /** 美世 */
    MERCER = 'mercer',
    /** 美加 */
    MEJ = 'mej',
    /** 易康吉 */
    EKANGJI = 'ekangji',
    /** 江泰 */
    JIANGTAI = 'jiangtai',
    ZHGJ= 'zhgj',

}

export enum HostPurpose {
    AGENCY = 'agency',
    CUSTOMER = 'customer',
    ANY = 'any'
}

export enum ForceSignIn {
    BEFORE_INTRO = 'before-intro',
    BEFORE_QUOTE = 'before-quote',
    AFTER_ISSUE = 'after-issue',
    NEVER = 'never'
}

export type Hosted = {
   /** 谁在使用 */
	by?: HostBy;
	wxAppId?: string;
	/** 使用者名字 */
	name?: string;
	/** 在什么时间点强制登陆 */
	forceSignIn?: ForceSignIn;
	/** 用途 */
	purpose?: HostPurpose;
	/** 是否开放投被保人注册 */
	customerSignUp?: boolean;
	/**wechatPrefixName微信appId标识 */
	wechatPrefixName: string;
	/** 是否在某个手机app当中 */
	app?: boolean;
	/** 从哪里跳到商城的 */
	from?: string;
	/** tenantCode */
	tenantCode?: string;
	/** 租户名称 **/
	tenantName?: string;
}
export const asHostBy = (): Hosted => {
    // console.log(window.location.hostname);
    switch (window.location.hostname) {
        /**中汇国际 */
		case ZHGJ_TO_GZH_HOST:
        return {
            by: HostBy.ZHGJ,
            name: '中汇国际',
            forceSignIn: ForceSignIn.BEFORE_QUOTE,
            purpose: HostPurpose.AGENCY,
            wechatPrefixName: ZHGJ_TO_GZH_PREFIX,
            customerSignUp: false
        };
        case HARVESTINS_TO_GZH_HOST:{
            return {
                name: '嘉实保险经纪',
                wechatPrefixName: HARVESTINS_TO_GZH_PREFIX,
            };
        }
        /**永达理 */
        case EPLBKS_TOC_HOST:
            return {
                by: HostBy.YDL,
                name: '永达理',
                forceSignIn: ForceSignIn.BEFORE_QUOTE,
                purpose: HostPurpose.CUSTOMER,
                wechatPrefixName: EPLBKS_TOC_PREFIX,
                customerSignUp: false
            };
        case EPLBKS_TOATOC_HOST:
            return {
                by: HostBy.YDL,
                name: '永达理',
                forceSignIn: ForceSignIn.BEFORE_QUOTE,
                purpose: HostPurpose.AGENCY,
                wechatPrefixName: EPLBKS_TOATOC_PREFIX,
                customerSignUp: false
            };
        /** 上海海力 toC*/
        case SHHL_TOC_HOST:
            return {
                by: HostBy.SHHL,
                name: '上海海力',
                forceSignIn: ForceSignIn.BEFORE_QUOTE,
                purpose: HostPurpose.CUSTOMER,
                wechatPrefixName: SHHL_TOC_PREFIX,
                customerSignUp: false
            };
        /**上海海里toAtoC */
        case SHHL_TOATOC_HOST:
            return {
                by: HostBy.SHHL,
                name: '上海海力',
                forceSignIn: ForceSignIn.BEFORE_QUOTE,
                purpose: HostPurpose.AGENCY,
                wechatPrefixName: SHHL_TOATOC_PREFIX,
                customerSignUp: false
            };
        /**上海海里toA */
        case SHHL_TOA_HOST:
            return {
                by: HostBy.SHHL,
                name: '上海海力',
                forceSignIn: ForceSignIn.BEFORE_QUOTE,
                purpose: HostPurpose.AGENCY,
                wechatPrefixName: SHHL_TOA_PREFIX,
                customerSignUp: false
            };
        /** 保精灵 toC*/
        case BJL_TOC_HOST:
            return {
                by: HostBy.BJL,
                name: '保精灵',
                forceSignIn: ForceSignIn.BEFORE_QUOTE,
                purpose: HostPurpose.CUSTOMER,
                wechatPrefixName: BJL_TOC_PREFIX,
                customerSignUp: false
            };
        /**保精灵 toatoc */
        case BJL_TOATOC_HOST:
            return {
                by: HostBy.BJL,
                name: '保精灵',
                forceSignIn: ForceSignIn.BEFORE_QUOTE,
                purpose: HostPurpose.AGENCY,
                wechatPrefixName: BJL_TOATOC_PREFIX,
                customerSignUp: false
            };
        /**保精灵 toa */
        case BJL_TOA_HOST:
            return {
                by: HostBy.BJL,
                name: '保精灵',
                forceSignIn: ForceSignIn.BEFORE_QUOTE,
                purpose: HostPurpose.AGENCY,
                wechatPrefixName: BJL_TOA_PREFIX,
                customerSignUp: false
            };
        /** 美世 toC*/
        // case MERCER_TOC_HOST:
        // 	return {
        // 		by: HostBy.MERCER,
        // 		name: '美世',
        // 		forceSignIn: ForceSignIn.BEFORE_QUOTE,
        // 		purpose: HostPurpose.CUSTOMER,
        // 		wechatPrefixName: MERCER_TOC_PREFIX,
        // 		customerSignUp: false
        // 	};
        /**美世toAtoC */
        case MERCER_TOATOC_HOST:
            return {
                by: HostBy.MERCER,
                name: '美世',
                forceSignIn: ForceSignIn.BEFORE_QUOTE,
                purpose: HostPurpose.AGENCY,
                wechatPrefixName: MERCER_TOATOC_PREFIX,
                customerSignUp: false
            };
        /**美世toA */
        // case MERCER_TOA_HOST:
        // 	return {
        // 		by: HostBy.MERCER,
        // 		name: '美世',
        // 		forceSignIn: ForceSignIn.BEFORE_QUOTE,
        // 		purpose: HostPurpose.AGENCY,
        // 		wechatPrefixName: MERCER_TOA_PREFIX,
        // 		customerSignUp: false
        // 	};
        /** 美加 toC*/
        // case MEJ_TOC_HOST:
        // 	return {
        // 		by: HostBy.MEJ,
        // 		name: '美加',
        // 		forceSignIn: ForceSignIn.BEFORE_QUOTE,
        // 		purpose: HostPurpose.CUSTOMER,
        // 		wechatPrefixName: MEJ_TOC_PREFIX,
        // 		customerSignUp: false
        // 	};
        /**美加toAtoC */
        case MEJ_TOATOC_HOST:
		case MEJ_TOATOC_HOST2:
			return {
				by: HostBy.MEJ,
				tenantCode: 'MEJ',
				name: '美佳',
				forceSignIn: ForceSignIn.BEFORE_QUOTE,
				purpose: HostPurpose.AGENCY,
				wxAppId: MEJ_TOATOC_APP_ID,
				wechatPrefixName: MEJ_TOATOC_PREFIX,
				tenantName: '上海美世保险经纪有限公司',
				customerSignUp: false
			};

        /**美加toA */
        // case MEJ_TOA_HOST:
        // 	return {
        // 		by: HostBy.MEJ,
        // 		name: '美加',
        // 		forceSignIn: ForceSignIn.BEFORE_QUOTE,
        // 		purpose: HostPurpose.AGENCY,
        // 		wechatPrefixName: MEJ_TOA_PREFIX,
        // 		customerSignUp: false
        // 	};
        /**易康吉 */
        case EKANGJI_TOATOC_HOST:
            return {
                by: HostBy.EKANGJI,
                name: '易康吉',
                forceSignIn: ForceSignIn.BEFORE_QUOTE,
                purpose: HostPurpose.AGENCY,
                wechatPrefixName: EKANGJI_TOATOC_PREFIX,
                customerSignUp: false
            };
        /**江泰 */
        case JIANGTAI_TOATOC_HOST:
            return {
                by: HostBy.JIANGTAI,
                name: '江泰',
                forceSignIn: ForceSignIn.BEFORE_QUOTE,
                purpose: HostPurpose.AGENCY,
                wechatPrefixName: JIANGTAI_TOATOC_PREFIX,
                customerSignUp: false
            };
        case 'csms-prod.ebaocloud.com.cn':
        case 'csms-preprod.ebaocloud.com.cn':
        case 'csms-uat.ebaocloud.com.cn':
        case 'csms-test.ebaocloud.com.cn':
        case 'csms-dev.ebaocloud.com.cn':
        case 'localhost':
            // TODO 易保经代云
            // return {
            // 	by: HostBy.MERCER,
            // 	name: '美世',
            // 	forceSignIn: ForceSignIn.BEFORE_QUOTE,
            // 	purpose: HostPurpose.AGENCY,
            // 	wechatPrefixName: MERCER_TOATOC_PREFIX,
            // 	customerSignUp: false
            // };
            return {
                by: HostBy.MYSELF,
                name: '易保经代云',
                forceSignIn: ForceSignIn.BEFORE_QUOTE,
                purpose: HostPurpose.AGENCY,
                wechatPrefixName: '',
                customerSignUp: false
            };
        default:
            return {
                by: HostBy.MYSELF,
                name: '易保经代云',
                forceSignIn: ForceSignIn.BEFORE_QUOTE,
                purpose: HostPurpose.AGENCY,
                wechatPrefixName: '',
                customerSignUp: false
            };
    }
};

